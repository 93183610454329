import { createTheme } from "@mui/material/styles";

export interface CustomTheme {
  bg: {
    main: string;
    light: string;
  };
  border: {
    primary: string;
    secondary: string;
  };
}

const theme = createTheme({
  typography: {
    fontFamily: `"Helvetica Neue", "Helvetica", "Arial", "sans-serif"`,
  },
  palette: {
    primary: {
      light: "#fff",
      main: "#fff",
      dark: "#002884",
      contrastText: "#111", // black
    },
    secondary: {
      light: "#ff7961",
      main: "#111",
      dark: "#111",
      contrastText: "#fff", // white
    },
  },
  bg: {
    main: "red",
    light: "#F4F5F7",
  },
  border: {
    primary: "#ccc",
    secondary: "#262930",
  },
});

export default theme;
