import { useSyncExternalStore } from "react";
import { ToastItem, ToastProps } from "../Types";
import { toToastItem } from "../utils";

export type Listener = () => void;

function createStore({
  initialState,
}: {
  initialState: Map<string, ToastItem>;
}) {
  let subscribers: Listener[] = [];
  let state = initialState;

  const notifyStateChanged = () => {
    subscribers.forEach((fn) => fn());
  };

  return {
    subscribe(fn: Listener) {
      subscribers.push(fn);
      return () => {
        subscribers = subscribers.filter((listener) => listener !== fn);
      };
    },
    getSnapshot() {
      return state;
    },
    setState(newState: Map<string, ToastItem>) {
      state = newState;
      notifyStateChanged();
    },
    addToast(toast: ToastProps) {
      const toastItem = toToastItem(toast);
      state = new Map(state.set(toastItem.id, toastItem));
      notifyStateChanged();
    },
    removeToast(id: string) {
      state.delete(id);
      state = new Map(state);
      notifyStateChanged();
    },
    clearAll() {
      state = initialState;
      notifyStateChanged();
    },
  };
}

export function createUseStore(initialState: Map<string, ToastItem>) {
  const store = createStore({ initialState });
  return () =>
    [
      useSyncExternalStore(store.subscribe, store.getSnapshot),
      store.addToast,
      store.removeToast,
      store.getSnapshot,
    ] as const;
}
