import { ToastItem, ToastProps } from "../Types";
import { DEFAULT_TOAST_DURATION, DEFAULT_TOAST_TYPE } from "./Constants";

export function toToastItem(toast: ToastProps): ToastItem {
  return toast != null
    ? {
        id: getId(),
        type: toast.type || DEFAULT_TOAST_TYPE,
        data: { message: toast.message },
        autoHideDuration: toast.autoHideDuration || DEFAULT_TOAST_DURATION,
        icon: toast.icon,
        closeToast: toast.closeToast,
        isNode: toast?.isNode || false,
        node: toast?.node || undefined,
      }
    : // monkey patch for now
      ({} as ToastItem);
}

export function getId(): string {
  return Math.random().toString(36).substring(10);
}
