import { AegisClient } from "@nike/aegis-auth-react";
import { DEFAULT_MESSAGE } from "../shared/constants";
import { ROUTES } from "../shared/route-constants";
const { ERROR, LOGIN } = ROUTES;
import { ENVIRONMENT } from "../shared/constants";

export const aegisClient = new AegisClient({
  clientId: "nike.nde.copywriting-user",
  redirectUri: window.location.origin + "/login",
  qa: ENVIRONMENT !== "prod",
});

const setErrorPage = () => {
  if (
    window.location.pathname !== "/login" &&
    window.location.pathname !== "/error"
  ) {
    sessionStorage.setItem(
      "from",
      window.location.pathname + window.location.search
    );
  }
  //This error state occurs when invalid credentials, Incorrect endpoints, misconfiguration,issue with third party authentication providers
  sessionStorage.setItem("errorMessage", DEFAULT_MESSAGE);
  window.history.pushState({}, "Error", `/${ERROR}`);
  window.location.reload();
};

const setLoginPage = () => {
  if (
    window.location.pathname !== "/login" &&
    window.location.pathname !== "/error"
  ) {
    sessionStorage.setItem(
      "from",
      window.location.pathname + window.location.search
    );
  }
  sessionStorage.setItem("errorMessage", DEFAULT_MESSAGE);
  window.history.pushState({}, "Retry Login", `/${LOGIN}`);
  window.location.reload();
};

aegisClient.on("login:error", (error) => {
  setErrorPage();
});

aegisClient.on("renew:error", (error) => {
  setErrorPage();
});

aegisClient.on("login:expired", (error) => {
  setLoginPage();
});
