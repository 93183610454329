export const ENVIRONMENT = process.env.NODE_ENV;
const env = ENVIRONMENT === "prod" ? "prod" : "nonProd";

export const RolesConfig = {
  nonProd: {
    authoringRead: "App.Lexi.NonProd.Authoring.Read",
    authoringWrite: "App.Lexi.NonProd.Authoring.Write",
    authoringAdmin: "App.Lexi.NonProd.Authoring.Admin",
    localizationRead: "App.Lexi.NonProd.Localization.Read",
    localizationWrite: "App.Lexi.NonProd.Localization.Write",
    localizationAdmin: "App.Lexi.NonProd.Localization.Admin",
    productAttributes: "Application.LEXI.Users",
    productAttributesRead: "App.Lexi.NonProd.ProductAttributes.Read",
    productAttributesAdmin: "App.Lexi.NonProd.ProductAttributes.Admin",
  },
  prod: {
    authoringRead: "App.Lexi.Authoring.Read",
    authoringWrite: "App.Lexi.Authoring.Write",
    authoringAdmin: "App.Lexi.Authoring.Admin",
    localizationRead: "App.Lexi.Localization.Read",
    localizationWrite: "App.Lexi.Localization.Write",
    localizationAdmin: "App.Lexi.Localization.Admin",
    productAttributes: "Application.LEXI.Users",
    productAttributesRead: "App.Lexi.ProductAttributes.Read",
    productAttributesAdmin: "App.Lexi.ProductAttributes.Admin",
  },
};

const AuthoringRead = RolesConfig[env].authoringRead;
const AuthoringWrite = RolesConfig[env].authoringWrite;
const AuthoringAdmin = RolesConfig[env].authoringAdmin;

const LocalizationRead = RolesConfig[env].localizationRead;
const LocalizationWrite = RolesConfig[env].localizationWrite;
const LocalizationAdmin = RolesConfig[env].localizationAdmin;

const ProductAttributes = RolesConfig[env].productAttributes;
const ProductAttributesRead = RolesConfig[env].productAttributesRead;
const ProductAttributesAdmin = RolesConfig[env].productAttributesAdmin;

export const Roles = {
  AuthoringRead,
  AuthoringWrite,
  AuthoringAdmin,
  LocalizationRead,
  LocalizationWrite,
  LocalizationAdmin,
  ProductAttributes,
  ProductAttributesRead,
  ProductAttributesAdmin,
};

export const AllowedRolesAuthoring = [
  AuthoringRead,
  AuthoringWrite,
  AuthoringAdmin,
];

export const AllowedRolesLocalization = [
  LocalizationRead,
  LocalizationWrite,
  LocalizationAdmin,
];

export const AllowedRolesProductAttributes = [
  ProductAttributes,
  ProductAttributesRead,
  ProductAttributesAdmin,
];
