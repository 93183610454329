import { styled } from "@mui/material/styles";

export const NoDataContainer = styled("div")`
  width: 100%;
  height: 40vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 20px;
  & span {
    font-size: 16px;
    color: #757575;
  }
  border-radius: 8px;
`;
