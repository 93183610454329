import { Roles } from "./Roles";

export const createtHasAccess = (groups) => {
  const hasAccess = {
    hasReadAccessAuthoring: false,
    hasWriteAccessAuthoring: false,
    hasAdminAccessAuthoring: false,
    hasReadAccessProductAttributes: false,
    hasAdminAccessProductAttributes: false,
    hasReadAccessLocalization: false,
    hasWriteAccessLocalization: false,
    hasAdminAccessLocalization: false,
  };

  if (groups instanceof Array) {
    for (const group of groups) {
      if (Roles.AuthoringRead === group) {
        hasAccess.hasReadAccessAuthoring = true;
      }
      if (Roles.AuthoringWrite === group) {
        hasAccess.hasReadAccessAuthoring = true;
        hasAccess.hasWriteAccessAuthoring = true;
      }
      if (Roles.AuthoringAdmin === group) {
        hasAccess.hasReadAccessAuthoring = true;
        hasAccess.hasWriteAccessAuthoring = true;
        hasAccess.hasAdminAccessAuthoring = true;
      }
      // ProductAttributes
      if (
        Roles.ProductAttributes === group ||
        Roles.ProductAttributesRead === group
      ) {
        hasAccess.hasReadAccessProductAttributes = true;
      }
      if (Roles.ProductAttributesAdmin === group) {
        hasAccess.hasReadAccessProductAttributes = true;
        hasAccess.hasAdminAccessProductAttributes = true;
      }
      // Localization
      if (Roles.LocalizationRead === group) {
        hasAccess.hasReadAccessLocalization = true;
      }
      if (Roles.LocalizationWrite === group) {
        hasAccess.hasReadAccessLocalization = true;
        hasAccess.hasWriteAccessLocalization = true;
      }

      if (Roles.LocalizationAdmin === group) {
        hasAccess.hasReadAccessLocalization = true;
        hasAccess.hasWriteAccessLocalization = true;
        hasAccess.hasAdminAccessLocalization = true;
      }
    }
  }
  return hasAccess;
};
