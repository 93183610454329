/* eslint-disable react/prop-types */

import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";

import { GET_FEATURE_FLAGS } from "../graphql/queries";
import { localizationClient } from "../config/apollo-client";
import CircularProgressContainer from "../components/CircularProgressContainer/CircularProgressContainer";

const model = require("../models/featureFlags.json");

export interface FeatureFlagProps {
  id: string;
  enabled: boolean;
}

const FeatureFlagContext = React.createContext(model);

export const FeatureFlagsProvider = ({ children }) => {
  const [features, setFeatures] = useState<FeatureFlagProps[]>([]);

  const {
    loading: isMetaDataLoading,
    error: fetchMetaDataError,
    data: metaData,
  } = useQuery(GET_FEATURE_FLAGS, { client: localizationClient });

  useEffect(() => {
    if (!isMetaDataLoading && !fetchMetaDataError && metaData) {
      const { featureFlags } = metaData;
      if (featureFlags) {
        setFeatures(featureFlags);
      }
    }
  }, [isMetaDataLoading]);

  return (
    <FeatureFlagContext.Provider value={{ features }}>
      {isMetaDataLoading ? <CircularProgressContainer /> : children}
    </FeatureFlagContext.Provider>
  );
};

export const useFeatureFlags = () => React.useContext(FeatureFlagContext);
