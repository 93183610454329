import {
  GlobalActions,
  GlobalState,
} from "../@types/common/GlobalStateAndActionType";
import { create } from "zustand";
import { persist } from "zustand/middleware";

export const useGlobalStore = create<GlobalState & GlobalActions>()(
  persist(
    (set) => ({
      hasAccess: {
        hasReadAccessAuthoring: false,
        hasWriteAccessAuthoring: false,
        hasAdminAccessAuthoring: false,
        hasReadAccessProductAttributes: false,
        hasAdminAccessProductAttributes: false,
        hasReadAccessLocalization: false,
        hasWriteAccessLocalization: false,
        hasAdminAccessLocalization: false,
      },
      setHasAccess: (hasAccess: GlobalState["hasAccess"]) => set({ hasAccess }),
    }),
    {
      name: "global-store",
      partialize: (state) => ({
        hasAccess: state.hasAccess,
      }),
    }
  )
);
