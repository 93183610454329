import React from "react";
import Toast from "./Toast";
import { ToastItem } from "../Types";
import { ToastContainerStyled } from "./Toast.styled";
import { useToastStore } from "../hooks";

export function ToastContainer(): JSX.Element {
  const [getSnapshot] = useToastStore();
  const removeToast = useToastStore()[2];
  const toastItemList = Array.from(getSnapshot.values());

  return (
    <ToastContainerStyled
      id={"toast-container"}
      open={toastItemList.length > 0 ? true : false}
    >
      {toastItemList?.map((item: ToastItem) => (
        <Toast
          closeAlert={() => {
            removeToast(item.id.toString());
          }}
          key={item.id}
          toastItem={item}
        ></Toast>
      ))}
    </ToastContainerStyled>
  );
}
