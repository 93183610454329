import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { ROUTES } from "../../shared/route-constants";
import { NOT_AUTHORIZED_MESSAGE, PAGE_NOT_ALLOWED } from "../constants";
import { useAuth } from "@nike/aegis-auth-react";
import { aegisClient } from "../../config/aegis-config";
const { LOGIN, ERROR } = ROUTES;

interface RequireAuthProps {
  children: JSX.Element;
  allowedGroups?: string[];
}

export default function RequireAuth({
  allowedGroups,
  children,
}: RequireAuthProps): JSX.Element {
  const location = useLocation();
  const { isLoggedIn, tokenClaims } = useAuth(aegisClient);
  const groups: any = tokenClaims?.groups;

  if (!isLoggedIn()) {
    return <Navigate state={{ from: location }} to={`/${LOGIN}`} replace />;
  }

  const canViewPage = allowedGroups?.some((g) => groups.includes(g));
  if (!canViewPage) {
    if (sessionStorage)
      sessionStorage.setItem("errorMessage", NOT_AUTHORIZED_MESSAGE);
    return <Navigate to={`/${ERROR}`} />;
  } else if (!canViewPage) {
    if (sessionStorage)
      sessionStorage.setItem("errorMessage", PAGE_NOT_ALLOWED);
    return <Navigate to={`/${ERROR}`} />;
  }

  return children;
}
