/* eslint-disable react/prop-types */
import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

import { NoDataContainer } from "./CircularProgressContainer.styled";

const CircularProgressContainer = ({ spinnerSize = 40 }) => {
  return (
    <NoDataContainer>
      <CircularProgress color="inherit" size={spinnerSize} />
    </NoDataContainer>
  );
};

export default CircularProgressContainer;
